export const Roles = Object.freeze({
    designer: 'Designer',
    reviewer: 'Reviewer',
    designLeader: 'DesignLeader',
    reviewLeader: 'ReviewLeader',
    labAdmin: 'LabAdmin',
    admin: 'admin',
} as const);

export type Role = (typeof Roles)[keyof typeof Roles];
