import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum TreatmentDesignRevisionLiability {
    unknown = 0,
    doctor = 1,
    designer = 2,
    pending = 3,
}

export interface TreatmentDesignRevisionJudgement {
    id: number;
    treatmentId: string;
    originalDesignId: string;
    revisedDesignId: string;
    justiceId?: string;
    dateAdded?: string | Date;
    reasonId?: number | null;
    revisionId: string;
    liability: TreatmentDesignRevisionLiability;
}

export const createTreatmentDesignRevisionJudgementForm = (initConfig: { treatmentId: string }) =>
    new FormGroup(
        {
            id: new FormControl(
                { disabled: false, value: 0 },
                { nonNullable: true, validators: [Validators.required] }
            ),
            originalDesignId: new FormControl({ disabled: false, value: '' }, { nonNullable: true, validators: [] }),
            revisedDesignId: new FormControl({ disabled: false, value: '' }, { nonNullable: true, validators: [] }),
            comment: new FormControl<string | null>({ disabled: false, value: null }),
            revisionId: new FormControl({ disabled: false, value: '' }, { nonNullable: true, validators: [] }),
            liability: new FormControl<TreatmentDesignRevisionLiability>(
                { disabled: false, value: TreatmentDesignRevisionLiability.pending },
                { nonNullable: true, validators: [] }
            ),
            treatmentId: new FormControl(
                { disabled: false, value: initConfig.treatmentId },
                { nonNullable: true, validators: [] }
            ),
            reasonId: new FormControl({ disabled: false, value: 0 }, { nonNullable: false, validators: [] }),
        },
        {
            validators: [
                control => {
                    const liability: TreatmentDesignRevisionLiability = control.get('liability')?.value;
                    const reasonId: number | null = control.get('reasonId')?.value;
                    return liability === TreatmentDesignRevisionLiability.pending || (reasonId ?? 0) > 0
                        ? null
                        : {
                              reasonId: 'Reason Id is required',
                          };
                },
            ],
        }
    );

export type TreatmentDesignRevisionJudgementForm = ReturnType<typeof createTreatmentDesignRevisionJudgementForm>;

export type TreatmentDesignRevisionJudgementEditModel = TreatmentDesignRevisionJudgementForm['value'];
