import { inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { VersionHistory } from '../model/version-history.model';
import { HttpClient } from '@angular/common/http';
import { parseISO } from 'date-fns';

const basePath = '/api/version-histories';

@Injectable({
    providedIn: 'root',
})
export class VersionHistoryClient {
    private _client = inject(HttpClient);

    listVersionHistories(): Observable<VersionHistory[]> {
        return this._client.get<VersionHistory[]>(`${basePath}`).pipe(
            map(items =>
                items.map(item => ({
                    ...item,
                    timeStamp:
                        typeof item.timeStamp === 'number' && !isNaN(item.timeStamp)
                            ? new Date(item.timeStamp)
                            : item.timeStamp,
                }))
            ),
            tap(v => console.warn(v))
        );
    }

    markRead(version: string) {
        const formData = new FormData();
        formData.append('version', version);
        return this._client.post<VersionHistory[]>(`${basePath}/read-logs`, formData);
    }

    getContent(version: string): Observable<string> {
        return this._client.get(`${basePath}/${version}/content`, { responseType: 'text' });
    }
}
