import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { UserStore } from '../store/user.store';

const excludePaths: (string | RegExp)[] = [
    '/api/server-metadata',
    'design-lab-service-files-dev.s3.cn-northwest-1.amazonaws.com.cn',
    'design-lab-service-files-prod.s3.cn-northwest-1.amazonaws.com.cn',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly _user = inject(UserStore);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return excludePaths.some(e => (typeof e === 'string' ? request.url.includes(e) : e.test(request.url))) ||
            request.headers.has('Authorization')
            ? next.handle(request)
            : this._user.token$.pipe(
                  switchMap(token =>
                      next.handle(
                          request.clone({
                              headers: new HttpHeaders({
                                  Authorization: `Bearer ${token.accessToken}`,
                              }),
                          })
                      )
                  )
              );
    }
}
