import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { Auth0UserProfile, Authentication, AuthOptions, WebAuth } from 'auth0-js';
import { environment } from 'src/environments/environment';
import { ServerMetadataClient } from 'src/app/core/client/server-metadata-client';
import { UserToken } from 'src/app/core/model/user-token.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly _authOptions$: Observable<AuthOptions> = of(1).pipe(
        map(({}) => ({
            clientID: environment.auth0.clientID,
            domain: environment.auth0.domain,
            audience: environment.auth0.audience,
            scope: environment.auth0.scope,
            responseType: environment.auth0.responseType,
            redirectUri: location.origin,
        })),
        shareReplay(1)
    );
    private readonly _webAuth$ = this._authOptions$.pipe(
        map(authOptions => new WebAuth(authOptions)),
        shareReplay(1)
    );

    constructor(private _serverMetadataClient: ServerMetadataClient) {}

    logout() {
        this._webAuth$.pipe(take(1)).subscribe(webAuth =>
            webAuth.logout({
                returnTo: location.origin + '?redirectApp=designLabAssistant',
            })
        );
    }

    login() {
        window.location.href = window.location.origin + '/login';
    }

    fetchTokens() {
        return this._webAuth$.pipe(
            switchMap(
                webAuth =>
                    new Observable<UserToken>(s => {
                        webAuth.checkSession({}, (e, r) => {
                            if (e) {
                                console.warn('failed to checkSession', e);
                                this.login();
                                s.error(e);
                            } else {
                                s.next(r);
                            }

                            s.complete();
                        });

                        return s;
                    })
            ),
            take(1)
        );
    }
}
