<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
        <div class="container-fluid">
            <a class="navbar-brand" [routerLink]="['/']">
                <strong>月牙</strong>
                设计交付平台
                <span (click)="openVersionHistoryDialog()">
                    <small
                        class="text-secondary"
                        [class.pe-2]="isVersionUnread$ | async"
                        [class.version-no-unread-badge]="!(isVersionUnread$ | async)"
                        pBadge
                        severity="danger"
                        style="font-weight: normal; font-size: 13px"
                    >
                        <i>{{ latestVersion$ | async }}</i>
                    </small>
                    <!--                    <p-badge severity="danger" *ngIf="isVersionUnread$ | async"></p-badge>-->
                </span>
            </a>

            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                aria-label="Toggle navigation"
                [attr.aria-expanded]="isExpanded"
                (click)="toggle()"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                class="navbar-collapse collapse d-sm-inline-flex justify-content-between"
                [ngClass]="{ show: isExpanded }"
            >
                <ul class="navbar-nav flex-grow">
                    <li class="nav-item cursor-pointer">
                        <a
                            [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{
                                paths: 'exact',
                                queryParams: 'subset',
                                fragment: 'ignored',
                                matrixParams: 'ignored'
                            }"
                            [routerLink]="['/']"
                            class="nav-link"
                        >
                            订单
                        </a>
                    </li>
                    <li
                        class="nav-item cursor-pointer"
                        *ngIf="currentUser$ | async | hasAnyPermission : permissions.grant"
                    >
                        <a
                            [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{
                                paths: 'exact',
                                queryParams: 'subset',
                                fragment: 'ignored',
                                matrixParams: 'ignored'
                            }"
                            [routerLink]="['/', 'admin', 'members']"
                            class="nav-link"
                        >
                            用户管理
                        </a>
                    </li>
                </ul>
                <div class="d-flex align-items-center">
                    <div class="me-3" *ngIf="mainRole$ | async as mainRole">
                        <p-chip icon="pi pi-user" size="small" [label]="mainRole | translate"></p-chip>
                    </div>
                    <p-menu #avatarMenu [model]="(avatarItems$ | async) ?? []" [popup]="true"></p-menu>
                    <app-member-avatar
                        (click)="avatarMenu.toggle($event)"
                        backgroundColor="#e79f3c"
                        class="d-block mr-2 cursor-pointer"
                        [member]="currentUser$ | async"
                        size="large"
                    />
                </div>
            </div>
        </div>
    </nav>
</header>
