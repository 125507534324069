import { inject, Injectable } from '@angular/core';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { PageModel } from '../model/page-model.model';
import { Treatment } from '../model/treatment';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { ListPagedTreatmentQuery } from '../model/ListPagedTreatmentQuery';
import { TreatmentDetail } from '../model/treatmentDetail';
import { FileSyncProgressRecord } from '../model/file-sync-progress.model';
import { TreatmentDesign, TreatmentDesigns } from '../model/treatment-design.model';
import { TreatmentFileKind, TreatmentFiles } from '../model/treatment-file.model';
import { TreatmentNote } from '../model/treatment-note.model';
import { TreatmentType } from '../model/treatmentType';
import { TreatmentStatus } from '../model/treatment-status.model';
import { Base64ImageExtractService } from '../service/base64-image-extractor.service';
import { sortBy } from 'lodash-es';
import { TreatmentPendingStatusFilter } from '../model/treatment-pending-status-filter.enum';
import { RegenerateAiNightGuardInputModel } from '../model/regenerate-ai-night-guard-input-model';
import { BulkDeliveryResult, TreatmentBulkDeliveryInspection } from '../model/bulk-delivery';
import { TreatmentDesignNoteEditLogs } from '../model/treatment-design-note-edit-log';

const basePath = '/api/treatments';

@Injectable({ providedIn: 'root' })
export class TreatmentClient {
    private readonly _client = inject(HttpClient);
    private readonly _base64ImageExtractor = inject(Base64ImageExtractService);

    list(params: ListPagedTreatmentQuery = {}): Observable<PageModel<Treatment>> {
        const p: HttpParamsOptions['fromObject'] = {};
        for (const key in params) {
            const v = params[key as keyof ListPagedTreatmentQuery];
            if (v != null) p[key] = v instanceof Date ? v.toISOString() : v;
        }
        return this._client.get<PageModel<Treatment>>(basePath, {
            params: new HttpParams({ fromObject: p }),
        });
    }

    countPendingItems(pendingType: TreatmentPendingStatusFilter) {
        return this._client.get<PageModel<Treatment>>(basePath, {
            params: new HttpParams({
                fromObject: {
                    pendingStatus: pendingType,
                    pageSize: 1,
                    pageIndex: 1,
                },
            }),
        });
    }

    countPendingAnswerTreatments() {
        return this.countPendingItems(TreatmentPendingStatusFilter.pendingAnswer);
    }

    detail(treatmentId: string) {
        return this._client.get<TreatmentDetail>(`${basePath}/${treatmentId}/detail`);
    }

    getPatientFileSyncProgresses(fileGuid: string[]): Observable<FileSyncProgressRecord> {
        return this._client.get<FileSyncProgressRecord>(`${basePath}/patient-files/sync-progress`, {
            params: fileGuid.reduce((p, c) => p.append('fileGuid', c), new HttpParams()),
        });
    }

    getPatientFileDownloadLinks(fileGuid: string[]) {
        return this._client.get<Record<string, string>>(`${basePath}/patient-files/download-links`, {
            params: fileGuid.reduce((p, c) => p.append('fileGuid', c), new HttpParams()),
        });
    }

    getDesignFileDownloadLinks(treatmentId: string, designId: number, fileGuid: string[]) {
        return this._client.get<Record<string, string>>(
            `${basePath}/${treatmentId}/designs/${designId}/files/download-links`,
            {
                params: fileGuid.reduce((p, c) => p.append('fileGuid', c), new HttpParams()),
            }
        );
    }

    retrySyncPatientFile(...fileGuid: string[]) {
        return this._client.post(
            `${basePath}/patient-files:trigger-sync`,
            fileGuid.reduce((acc, c) => {
                acc.append('fileGuid', c);
                return acc;
            }, new FormData())
        );
    }

    createDesign(treatmentId: string, revisionId?: string | null): Observable<TreatmentDesign> {
        const formData = new FormData();
        if (revisionId) formData.append('revisionId', revisionId);
        return this._client.post<TreatmentDesign>(`${basePath}/${treatmentId}/designs`, formData);
    }

    listDesigns(treatmentId: string): Observable<TreatmentDesigns> {
        return this._client.get<TreatmentDesigns>(`${basePath}/${treatmentId}/designs`).pipe(
            map(d => {
                return d.map(i => ({
                    ...i,
                    files: sortBy(i.files, x =>
                        x.fileKind === TreatmentFileKind.Engineering ? Number.MAX_VALUE : x.id
                    ),
                }));
            })
        );
    }

    removeDesignFile(treatmentId: string, designId: number, fileGuid: string) {
        return this._client.delete<void>(`${basePath}/${treatmentId}/designs/${designId}/files/${fileGuid}`);
    }

    assignReviewer(treatmentId: string, designId: number, reviewerId: string): Observable<void> {
        const body = new FormData();
        body.append('reviewerId', reviewerId);
        return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/reviewer`, body);
    }

    requestReview(treatmentId: string, designId: number) {
        return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/review/status:requestReview`, {});
    }

    putDesignNote(treatmentId: string, designId: number, value: string) {
        const body = new FormData();
        body.append('designNote', value.slice(0, 5000));
        return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/note`, body);
    }

    listDesignNoteEditLogs(treatmentId: string, designId: number) {
        return this._client.get<TreatmentDesignNoteEditLogs>(
            `${basePath}/${treatmentId}/designs/${designId}/note/edit-logs`
        );
    }

    acceptReview(treatmentId: string, designId: number, reviewId: number) {
        const body = new FormData();
        body.append('reviewId', `${reviewId}`);
        return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/review:accept`, body);
    }

    rejectReview(treatmentId: string, designId: number, reviewId: number, reason: string) {
        const body = new FormData();
        body.append('reviewId', `${reviewId}`);
        return from(reason ? this._base64ImageExtractor.extract(reason) : of('')).pipe(
            switchMap(r => {
                body.append('reason', r);
                return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/review:reject`, body);
            })
        );
    }

    putIsDesignUnread(treatmentId: string, designId: number, isUnread: boolean) {
        const body = new FormData();
        body.append('isUnread', `${isUnread}`);
        return this._client.put<void>(`${basePath}/${treatmentId}/designs/${designId}/isUnread`, body);
    }

    markDesignRead(treatmentId: string, designId: number) {
        return this.putIsDesignUnread(treatmentId, designId, false);
    }

    listTreatmentFilesByFileType(treatmentId: string, designId: number, fileType: number): Observable<TreatmentFiles> {
        return this._client.get<TreatmentFiles>(`${basePath}/${treatmentId}/designs/${designId}/files/${fileType}`);
    }

    listNotes(treatmentId: string): Observable<TreatmentNote[]> {
        return this._client.get<TreatmentNote[]>(`${basePath}/${treatmentId}/notes`);
    }

    bulkListNotes(treatmentIds: string[]) {
        return this._client.post<Record<string, TreatmentNote[]>>(`${basePath}/notes:bulkList`, treatmentIds);
    }

    saveNote(treatmentId: string, noteId: number, body: { content: string }) {
        return from(this._base64ImageExtractor.extract(body.content)).pipe(
            switchMap(content => {
                body.content = content;
                return this._client.post<TreatmentNote>(`${basePath}/${treatmentId}/notes/${noteId}`, body);
            })
        );
    }

    exportCsv(params: ListPagedTreatmentQuery = {}) {
        const p: HttpParamsOptions['fromObject'] = {};
        for (const key in params) {
            const v = params[key as keyof ListPagedTreatmentQuery];
            if (v != null) p[key] = v instanceof Date ? v.toISOString() : v;
        }
        return this._client.get(`${basePath}:exportCsv`, {
            responseType: 'blob',
            params: new HttpParams({
                fromObject: p,
            }),
        });
    }

    listTreatmentTypes(): Observable<TreatmentType[]> {
        return this._client.get<TreatmentType[]>(`/api/treatments/types`);
    }

    markRedesign(treatmentId: string, requirement?: string | null) {
        const form = new FormData();
        return from(requirement ? this._base64ImageExtractor.extract(requirement) : of(undefined)).pipe(
            switchMap(r => {
                if (!!r) form.append('requirement', r);
                return this._client.post<void>(`${basePath}/${treatmentId}:markRedesign`, form);
            })
        );
    }

    onPatientFilesBulkDownloaded(treatmentIds: string[]) {
        return this._client.post<Record<string, TreatmentStatus | null | undefined>>(
            `${basePath}/patient-files:bulk-downloaded`,
            treatmentIds
        );
    }

    listStatuses() {
        return this._client.get<TreatmentStatus[]>(`${basePath}/statuses`);
    }

    getRedesignRequirement(treatmentId: string) {
        return this._client.get(`${basePath}/${treatmentId}/redesignRequirement`, { responseType: 'text' });
    }

    getReviewRejectReason(treatmentId: string, reviewId: number): Observable<string> {
        return this._client.get(`${basePath}/${treatmentId}/reviews/${reviewId}/rejectReason`, {
            responseType: 'text',
        });
    }

    regenerateAiNightGuard(treatmentId: string, model: RegenerateAiNightGuardInputModel) {
        if (!model.upper || !model.lower) throw new Error('Upper and lower scans are required!');

        const formData = new FormData();
        formData.set('upper', model.upper);
        formData.set('lower', model.lower);
        return this._client.post(`${basePath}/${treatmentId}:regenerate-ai-night-guard`, formData);
    }

    inspectBulkDelivery(treatmentId: string[]) {
        return this._client.post<Record<string, TreatmentBulkDeliveryInspection>>(
            `${basePath}:inspect-bulk-delivery`,
            treatmentId.reduce((prev, c) => {
                prev.append('treatmentIds', c);
                return prev;
            }, new FormData())
        );
    }

    deliveryDesign(treatmentId: string, data: { messageBody: string; batchId?: string }) {
        return this._client.post<BulkDeliveryResult>(`${basePath}/${treatmentId}:delivery-design`, data);
    }

    handleStuckProgress(treatmentId: string) {
        return this._client.patch<void>(`${basePath}/${treatmentId}/deliveries:handle-stuck-progress`, {});
    }
}
